const links = [
  {
    label: 'Github',
    url: 'https://github.com/icaroov',
  },
  {
    label: 'Linkedin',
    url: 'https://www.linkedin.com/in/icaroov/',
  },
  {
    label: 'Email',
    url: 'mailto:icarovinici@gmail.com',
  },
]

export default links
